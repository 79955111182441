@import '~site/variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/grid';
@import '~site/settings/variables';
@import '~site/mixins';

$ns: 'headline-section';
$custom-gutter: 30;
$custom-gutter-xl: 55;

.#{$ns} {
    padding-bottom: rem-calc(54);
    padding-top: rem-calc(54);

    &.normal {
        .#{$ns}__main-img .photo-tile-container {
            @include media-breakpoint-up(lg) {
                aspect-ratio: 934 / 524;
            }
        }

        .#{$ns}__img {
            &:nth-child(2) .photo-tile-container {
                @include media-breakpoint-up(lg) {
                    aspect-ratio: 884 / 514;
                }
            }

            &:nth-child(3) .photo-tile-container {
                @include media-breakpoint-up(lg) {
                    aspect-ratio: 673 / 514;
                }
            }
        }
    }

    &.decorated {
        background: $offwhite;

        .photo-tile-container {
            @include media-breakpoint-up(lg) {
                aspect-ratio: 654 / 467;
            }
        }

        .#{$ns}__content {
            @include alignText($xs: left, $md: center, $lg: left);
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: rem-calc(175);
            padding-top: rem-calc(125);
        }
    }

    &.reverse {
        .#{$ns}__slider {
            margin-top: 0;

            .#{$ns}__img {
                &:nth-child(2) {
                    @include media-breakpoint-up(lg) {
                        @include make-col(6);

                        padding-right: rem-calc(13);
                    }

                    @include media-breakpoint-up(xl) {
                        @include make-col(7);

                        padding-right: rem-calc(13);
                    }
                }

                &:nth-child(3) {
                    @include media-breakpoint-up(lg) {
                        @include make-col(6);

                        padding-left: rem-calc(13);
                    }

                    @include media-breakpoint-up(xl) {
                        @include make-col(5);

                        padding-left: rem-calc(13);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(26);
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: rem-calc(34);
            }
        }

        .#{$ns}__content {
            @include media-breakpoint-down(md) {
                margin-bottom: rem-calc(44);
            }

            @include media-breakpoint-up(lg) {
                padding-top: rem-calc(30);
            }

            @include media-breakpoint-up(xl) {
                padding-top: rem-calc(60);
            }
        }

        .#{$ns}__main-img {
            .headline-section__img {
                @include media-breakpoint-up(lg) {
                    padding-left: rem-calc(13);
                }
            }

            .photo-tile-container {
                @include media-breakpoint-only(lg) {
                    aspect-ratio: 356 / 306;
                    height: auto;
                    width: auto;
                }
            }
        }

        &::after {
            @include media-breakpoint-up(lg) {
                background-color: $offwhite;
                content: '';
                display: block;
                height: rem-calc(374);
                left: 0;
                position: absolute;
                top: rem-calc(-124);
                width: 100%;
                z-index: -1;
            }
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: rem-calc(80);
            margin-top: rem-calc(192);
            padding: 0;
            position: relative;
        }

        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(246);
        }
    }

    &__main-img {
        .#{$ns}__img {
            &:not(:first-child) {
                display: none;
            }
        }

        .decorated & {
            @include media-breakpoint-up(lg) {
                margin-top: rem-calc(74);
            }

            @include media-breakpoint-up(xl) {
                padding-right: rem-calc(25);
            }
        }
    }

    &__small-imgs {
        position: relative;
        width: 100%;

        .#{$ns}__imgs-wrapper {
            margin-bottom: rem-calc(-$custom-gutter / 2);
            margin-top: rem-calc(-$custom-gutter / 2);
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(xl) {
                margin-bottom: rem-calc(-$custom-gutter-xl / 2);
                margin-top: rem-calc(-$custom-gutter-xl / 2);
            }
        }

        .#{$ns}__img {
            display: none;

            &:nth-child(2),
            &:nth-child(3) {
                display: block;
                padding-bottom: rem-calc($custom-gutter / 2);
                padding-top: rem-calc($custom-gutter / 2);

                @include media-breakpoint-up(xl) {
                    padding-bottom: rem-calc($custom-gutter-xl / 2);
                    padding-top: rem-calc($custom-gutter-xl / 2);
                }
            }
        }
    }

    &__decoration {
        display: none;

        @include media-breakpoint-up(xl) {
            aspect-ratio: 566 / 1084;
            background-color: $beige;
            border: rem-calc(16) solid $beige;
            box-shadow: inset 0 0 0 rem-calc(1) $white;
            display: block;
            position: absolute;
            right: -22.75%;
            top: 50%;
            transform: translateY(-50%);
            width: 86.67%;
            z-index: 0;
        }
    }

    &__img {
        div,
        figure,
        img {
            height: 100%;
        }

        .photo-tile-image {
            object-fit: cover;
        }
    }

    &__slider {
        margin-top: rem-calc(44);

        .#{$ns}__img {
            &:nth-child(2) {
                @include media-breakpoint-up(lg) {
                    display: block;
                    flex-basis: 50%;
                    padding-right: rem-calc($custom-gutter / 2);
                }

                @include media-breakpoint-up(xl) {
                    flex-basis: rem-calc(912);
                    padding-right: rem-calc($custom-gutter-xl / 2);
                }
            }

            &:nth-child(3) {
                @include media-breakpoint-up(lg) {
                    display: block;
                    flex-basis: 50%;
                    padding-left: rem-calc($custom-gutter / 2);
                }

                @include media-breakpoint-up(xl) {
                    flex-basis: rem-calc(700);
                    padding-left: rem-calc($custom-gutter-xl / 2);
                }
            }

            @include media-breakpoint-up(lg) {
                display: none;
                flex: 1 1;
            }
        }

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__content {
        @include alignText($xs: center, $xl: left);
    }

    figure {
        margin-bottom: 0;
        overflow: hidden;
    }

    .photo-tile-container {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(150);
        padding-top: rem-calc(150);
    }
}

.headline {
    &__eyebrow {
        @include eyebrow();
    }

    &__heading {
        @include heading();
    }

    &__description {
        @include description($sm-line-height: 1.5);

        margin-bottom: rem-calc(36);
        padding: 0 rem-calc(5);

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin-bottom: inherit;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: rem-calc(22);
            max-width: rem-calc(463);

            .decorated & {
                max-width: rem-calc(512);
            }
        }
    }

    &__btn-link {
        .decorated & {
            @include media-breakpoint-down(md) {
                @include button($accent);
            }

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }

        @include media-breakpoint-up(lg) {
            text-transform: none;
        }
    }
}
